import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const Usave = () => {
  return (
    <Layout>
      <SEO title="Usave" description="" />
      <div className="consulting-wrapper wrapper py-5">
        <div className="row">
          <div className="col-12">
            <Image src="usave.webp" />
          </div>
        </div>
        <h1 className="mt-3">U Save Services</h1>
        <span>
          U Save Services has stayed true to its original mission: bring
          educated buyers and sellers together at the time they are ready to do
          business. <br /> With huge success since 2018, we are still growing.
          The following are the achievements we are always proud of.
        </span>
        <ul>
          <li>9000+ merchants</li>
          <li>Nationwide supplier</li>
          <li>Extensive collection of Products</li>
          <li>Services covering more than 70 categories</li>
        </ul>
        <p>
          U Save Services is providing ease to numerous businesses every day. It
          has been possible only by U Save Services that businesses now can
          access 70+ categories just by few clicks.
        </p>
        <p>
          We have been able to connect with 9000+ educated suppliers nationwide
          which is the biggest strength of this company. These 9000+ supplier's
          quotes help the businesses decide what best suits them.
        </p>
      </div>
    </Layout>
  )
}

export default Usave
